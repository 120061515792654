// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-docs-api-mdx": () => import("./../../../src/pages/docs/api.mdx" /* webpackChunkName: "component---src-pages-docs-api-mdx" */),
  "component---src-pages-docs-pkg-cli-mdx": () => import("./../../../src/pages/docs/pkg/cli.mdx" /* webpackChunkName: "component---src-pages-docs-pkg-cli-mdx" */),
  "component---src-pages-docs-pkg-core-mdx": () => import("./../../../src/pages/docs/pkg/core.mdx" /* webpackChunkName: "component---src-pages-docs-pkg-core-mdx" */),
  "component---src-pages-docs-pkg-readme-mdx": () => import("./../../../src/pages/docs/pkg/readme.mdx" /* webpackChunkName: "component---src-pages-docs-pkg-readme-mdx" */),
  "component---src-pages-docs-pkg-wayback-mdx": () => import("./../../../src/pages/docs/pkg/wayback.mdx" /* webpackChunkName: "component---src-pages-docs-pkg-wayback-mdx" */),
  "component---src-pages-docs-readme-mdx": () => import("./../../../src/pages/docs/readme.mdx" /* webpackChunkName: "component---src-pages-docs-readme-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-learn-results-mdx": () => import("./../../../src/pages/learn/results.mdx" /* webpackChunkName: "component---src-pages-learn-results-mdx" */),
  "component---src-pages-learn-specificity-mdx": () => import("./../../../src/pages/learn/specificity.mdx" /* webpackChunkName: "component---src-pages-learn-specificity-mdx" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-uses-mdx": () => import("./../../../src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */)
}

